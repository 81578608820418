.contact-submessage-containter{
    margin-top: 4rem;
    color: rgb(250, 31, 31);
}

.contact-submessage{
    font-size: 2.5rem;
}

.form-container{
    display: flex;
    max-width: 50%;
    justify-content: center;
    margin-top: 5rem;
    padding-bottom: 7rem;
}

.contact-name,.contact-email{
    width: 50%;
    padding: 4px 10px;
}

.contact-text{
    width: 100%;
    margin-top: 1rem;
    min-height: 10rem;
    padding: 4px 10px;
}

.contact-btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    padding: 6px 0;
}
.contact-button{
    width: 4rem;
    background-color: white;
    text-decoration: none;
    min-height: 2rem;
    border: none;
    transition-duration: 200ms ;
}

input,textarea{
    font-size: 1.5rem;
    letter-spacing: 1.3px;
}

.contact-sendIcon{
    width: 100%;
}

.contact-sendText{
    padding: 0;
    margin: 5px 0;
    line-height: 7px;
    letter-spacing: 1.9px;
}

.contact-button:hover{
    cursor: pointer;
    transform: scale(1.1);
}

@media (max-width:990px){
    .form-container{
        min-width: 90%;
        max-width: 95%;
        flex-direction: column;
    }
    .contact-name,.contact-email{
        width: 100%;
        margin-top: 20px;
    }
    .contact-submessage-containter{
        max-width: 90%;
        margin-top: 2rem;
    }
    .contact-submessage{
        font-size: 2rem;
        text-align: center;
    }
    .form-container{
        margin-top: 2rem;
    }
    .contact-btn-container{
        padding: 0;
        margin-top: 1rem;
    }
}
