*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
 --dark: rgb(22, 22, 22);
}

h1,h2,h3,input,textarea,p{
  font-family: 'Amatic SC', cursive;
  font-weight: 700;
}

.page-title{
  margin: 0 auto;
  text-align: center;
  font-size: 4rem;
}

a{
  text-decoration: none;
  color: var(--dark)
}


@media (max-width:990px){
  .page-title{
    font-size: 3rem;
  }

}