.proj-gallery-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
    background-color: rgb(252, 252, 252);
    padding-bottom: 10rem;
}

.proj-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:95%;
    gap: 15px;
    margin-top: 10px;
    z-index: 3;
    
}

.proj-image-div{
    height:25vh;
    min-width:50%;
    overflow: hidden;
    border: 3px solid black;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}


.people_btn, .food_btn, .comms_btn {
    transform: scale(.5);
}

.patterns_btn, .animal_btn, .book_btn {
    transform: scale(.6);
}

.proj-img{
    height: 100%;
    width:100%;
    object-fit: cover;
    transition: transform .7s;
    filter: blur(1.5px) ;
}

.proj-title-container{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.proj-title-box{
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 3px 4px 6px #181818;
}

.projects-title{
    color: var(--dark);
    margin:0 2.5rem;
    font-size: 2rem;
}

/* IMAGE POSITIONING */
.red-face, .hotdog{
    object-position: 0 -220px;
}

.childrens-book{
    object-position: 0 -60px;
}


/* SMALL SCREENS */
@media (max-width:990px){
    .proj-gallery-container{
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 2rem;
    }

    .proj-row{
        width: 100%;
        gap: 0px;
        flex-direction: column;
        margin-top: 0;
    }
    .proj-image-div{
        min-width: 30%;
        max-height:40%;
        width: 100%;
        object-fit: contain ;
        border-radius: 0;
        background-color: var(--dark);
        border-left: 0;
        border-right: 0;
    }
    .proj-img{
        border-radius: 0;
        height:100%;
       
    }
    .proj-title-box{
        left: 30%;
    }
}