.about-me-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;
    flex-direction: row;
    margin-top: 3rem;
}

.about-photo-container{
    /* position: relative; */
    max-width: 20%;
    min-height: 50%;
    /* max-height: 70vh; */
    overflow: hidden;
    margin-left: 1.5rem;
    border-radius: 5px;
}

.about-photo{
    /* width:100%; */
    height: 100%;
    object-fit: cover;
    object-position: -200px 0px;
}

.about-text-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width:60%;
    max-width: 70%;
    border: solid var(--dark) 1px;
    margin-left: 3%;
    border-radius: 5px;
    padding-top: 1rem;
}

.about-me-text{
    font-size: 2rem;
    text-align: center;
    margin: 0 1rem;
}





@media (max-width:990px){
    .about-me-container{
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        padding-bottom: 0;
    }
    .about-photo-container{
        max-width: 50%;
        min-height: 0;
        max-height: 10%;
        margin-bottom: 1rem;
    }

    .about-photo{
        object-fit: contain;
        width: 100%;
        object-position: -20px 0px;
        transform: scale(120%);
    }
   .about-text-container{
    margin:0;
    max-width: 90%;
   }
}